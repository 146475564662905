// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wiBIAwS_n"];

const variantClassNames = {wiBIAwS_n: "framer-v-1n6dhib"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "wiBIAwS_n", title: v7KYpKTlp = "Work", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wiBIAwS_n", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LXYcf", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 4739, intrinsicWidth: 3159, pixelHeight: 4739, pixelWidth: 3159, sizes: "1200px", src: new URL("assets/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href} 341w, ${new URL("assets/1024/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href} 682w, ${new URL("assets/2048/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href} 1365w, ${new URL("assets/4096/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href} 2730w, ${new URL("assets/jYtRXTihzMZfa5IbHBsAXoRQUHI.jpg", import.meta.url).href} 3159w`}} className={cx("framer-1n6dhib", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wiBIAwS_n"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--framer-font-size": "72px", "--framer-letter-spacing": "-0.09em", "--framer-line-height": "0.83em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-gdpscs)"}}>Work</motion.h1></React.Fragment>} className={"framer-1czi6pb"} layoutDependency={layoutDependency} layoutId={"bzBcQ_Rhg"} style={{"--extracted-gdpscs": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={v7KYpKTlp} transition={transition} verticalAlignment={"top"} withExternalLayout/></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LXYcf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LXYcf .framer-125rnil { display: block; }", ".framer-LXYcf .framer-1n6dhib { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 400px; justify-content: flex-start; overflow: hidden; padding: 0px 20px 0px 20px; position: relative; width: 1200px; }", ".framer-LXYcf .framer-1czi6pb { bottom: 33px; flex: none; height: auto; left: 27px; position: absolute; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-LXYcf .framer-1n6dhib { gap: 0px; } .framer-LXYcf .framer-1n6dhib > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-LXYcf .framer-1n6dhib > :first-child { margin-top: 0px; } .framer-LXYcf .framer-1n6dhib > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"v7KYpKTlp":"title"}
 */
const FramerEVaL2zVLd: React.ComponentType<Props> = withCSS(Component, css, "framer-LXYcf") as typeof Component;
export default FramerEVaL2zVLd;

FramerEVaL2zVLd.displayName = "Section Header";

FramerEVaL2zVLd.defaultProps = {height: 400, width: 1200};

addPropertyControls(FramerEVaL2zVLd, {v7KYpKTlp: {defaultValue: "Work", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerEVaL2zVLd, [])